$blue: #49a4e9;
$pink: #ff5385;

// From adjacent:
// http://paletton.com/#uid=53x0u0knwTgc8XZimVDszPTE6Ky
$green: #00d67a;

// From triad:
// http://paletton.com/#uid=33x0u0knwTgc8XZimVDszPTE6Ky
$orange: #ff7944;
$yellow: #ffd044;

$brand-primary: $blue;
$brand-success: $green;
$brand-info: shade-color($brand-primary, 7);
$brand-warning: $orange;
$brand-danger: $pink;
$brand-secondary: $pink; // $pink is not just for danger (alerts/errors)

//does not work with colors containing alpha
@function encode_color($string) {
  @if type-of($string) == 'color' {
    $hex: str-slice(ie-hex-str($string), 4);
    $string: unquote("#{$hex}");
  }
  $string: '%23' + $string;
  @return $string;
}

@function shade-color ($color, $i) {
  @if ($i < 0 or $i > 11) {
    @error "shade index needs to be between 0 and 11 (inclusive).";
  }
  @return mix(black, $color, ($i - 1) * 8% + 12%);
}

.waiting-animation-fallback {
  display: none;
}

.waiting-animation {
  position: relative;
  z-index: 2;
  overflow: hidden;
  text-align: center;
  line-height: 1;
}
.waiting-icon {
  opacity: 0;
  display: inline-block;
  margin-left: 2px;
  width:  0.631em;
  height: 0.714em;
  background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 145 164'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.42 81.97A48.34 48.34 0 01.44 45.15C.44 19.94 20.25.14 45.44.1c25.2-.02 46.14 18.41 73.6 47.3 3.68 3.88 21.24 23.23 22.36 24.64 4.24 5.38 4.24 13.88 0 19.83-1.02 1.42-18.72 20.8-22.37 24.64-27.45 28.89-48.4 47.3-73.59 47.3-25.19 0-45-19.83-45-45.03a48.34 48.34 0 0116.98-36.82zm84.36-8.94a8.93 8.93 0 11-.02 17.87 8.93 8.93 0 01.02-17.87z' fill='%23DE1B7A'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover;
}

.waiting-icon:nth-child(2) {
  background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 145 164'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.42 81.97A48.34 48.34 0 01.44 45.15C.44 19.94 20.25.14 45.44.1c25.2-.02 46.14 18.41 73.6 47.3 3.68 3.88 21.24 23.23 22.36 24.64 4.24 5.38 4.24 13.88 0 19.83-1.02 1.42-18.72 20.8-22.37 24.64-27.45 28.89-48.4 47.3-73.59 47.3-25.19 0-45-19.83-45-45.03a48.34 48.34 0 0116.98-36.82zm84.36-8.94a8.93 8.93 0 11-.02 17.87 8.93 8.93 0 01.02-17.87z' fill='%234F3AF5'/%3E%3C/svg%3E");
}

.waiting-icon:nth-child(3) {
  background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 145 164'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.42 81.97A48.34 48.34 0 01.44 45.15C.44 19.94 20.25.14 45.44.1c25.2-.02 46.14 18.41 73.6 47.3 3.68 3.88 21.24 23.23 22.36 24.64 4.24 5.38 4.24 13.88 0 19.83-1.02 1.42-18.72 20.8-22.37 24.64-27.45 28.89-48.4 47.3-73.59 47.3-25.19 0-45-19.83-45-45.03a48.34 48.34 0 0116.98-36.82zm84.36-8.94a8.93 8.93 0 11-.02 17.87 8.93 8.93 0 01.02-17.87z' fill='%23218FE4'/%3E%3C/svg%3E");
}

$wait-icon-move-distance: 40px;

@keyframes wait-icon-move {
  0% {
    opacity: 0;
    transform: translateX(-$wait-icon-move-distance);
  }
  33% {
    opacity: 1;
    transform: translateX(0px);
  }
  66% {
    opacity: 1;
    transform: translateX(0px);
  }
  100% {
    opacity: 0;
    transform: translateX($wait-icon-move-distance);
  }
}

@-webkit-keyframes wait-icon-move {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-$wait-icon-move-distance);
  }
  33% {
    opacity: 1;
    -webkit-transform: translateX(0px);
  }
  66% {
    opacity: 1;
    -webkit-transform: translateX(0px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX($wait-icon-move-distance);
  }
}

.waiting-icon:nth-child(1) {
  -webkit-animation: wait-icon-move 1.5s 300ms infinite ease-in-out;
  animation: wait-icon-move 1.5s 300ms infinite ease-in-out;
}

.waiting-icon:nth-child(2) {
  -webkit-animation: wait-icon-move 1.5s 200ms infinite ease-in-out;
  animation: wait-icon-move 1.5s 200ms infinite ease-in-out;
}

.waiting-icon:nth-child(3) {
  -webkit-animation: wait-icon-move 1.5s 100ms infinite ease-in-out;
  animation: wait-icon-move 1.5s 100ms infinite ease-in-out;
}

.waiting-animation {
  display: none;
}

@supports (transform: none) and (animation: none) {
  .waiting-animation-fallback {
    display: none;
  }

  .waiting-animation {
    display: block;
  }
}
